<template>
    <div style="position: relative;">
        <router-link to="/subMsg" v-if="$store.getters.userInfo.manage === 1">
            <span class="notice-manage">通知管理</span>
        </router-link>
        <message-tabs ref="adTabsRef" :type-list="typeList" @change="e => changeType(e)">
            <template #default>
                <el-row type="flex" style="height: 100%;">
                    <div class="left-container">
                        <div class="header">
                            <ul>
                                <li :class="{ 'active': tabIndex === 0 }" @click="changeTab(0)">全部({{ readTotal }})</li>
                                <li :class="{ 'active': tabIndex === 1 }" @click="changeTab(1)">未读({{ unreadTotal }})
                                </li>
                            </ul>
                            <el-tooltip effect="dark" content="全部标记为已读" placement="top">
                                <i class="iconfont icon-quanbubiaojiweiyidu"
                                    :style="{ 'color': unreadTotal === 0 ? '#d9d9d9' : '#2b82ff' }"
                                    @click="clearAllUnRead" />
                            </el-tooltip>
                        </div>
                        <div class="empty" v-if="tabIndex === 1 && list.length === 0 && unreadPage === 2">
                            <img src="@/assets/images/message/empty.svg" alt="">
                            <div class="title">你已阅完所有通知</div>
                        </div>
                        <div class="list" v-else>
                            <div class="item" v-for="item in list" :key="item.id" @click="clickMessage(item)"
                                :style="{ 'background': messageSelected?.id === item.id ? 'rgba(43,130,255,0.1)!important' : '#ffffff' }">
                                <div :class="['title', item.is_read === 0 ? 'active' : '']">{{ item.title }}</div>
                                <div class="content">{{ item.content.replace(/<[^>]*>/g, '') }}</div>
                                <div class="time">{{ item.time }}</div>
                            </div>
                            <div class="divider" v-show="list.length >= 20" v-loading="loading" ref="loadingRef">我到底啦~
                            </div>
                        </div>
                    </div>
                    <div class="right-container">
                        <div v-if="messageSelected" class="select">
                            <div class="title">{{ messageSelected.title }}</div>
                            <div class="content" v-html="messageSelected.content"></div>
                            <div class="time">{{ messageSelected.time }}</div>
                        </div>
                        <div v-else class="unselect">
                            <img src="@/assets/images/message/unselect.svg" alt="">
                            <div class="title">选择要阅读的项目</div>
                            <div class="content">未选择任何内容</div>
                        </div>
                    </div>
                </el-row>
            </template>
        </message-tabs>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import MessageTabs from '@/views/notice/MessageTabs.vue'
import api from '@/request/api/message'

const { proxy } = getCurrentInstance()

const typeId = ref(1)
const tabIndex = ref(0)
const messageSelected = ref(null)
const typeList = ref([])
const list = ref([])
const readPage = ref(1)
const unreadPage = ref(1)
const readTotal = ref(0)
const unreadTotal = ref(0)
const loading = ref(true)
const loadingRef = ref(null)
const observer = ref(null)

const resetConfig = () => {
    messageSelected.value = null
    list.value = []
    readPage.value = 1
    unreadPage.value = 1
    loading.value = true
}

/** 切换消息类型 */
const changeType = async (id) => {
    typeId.value = id
    tabIndex.value = 0
    readTotal.value = 0
    unreadTotal.value = 0
    resetConfig()
    getReadAndUnReadTotal()
    await getMessageList()
    handleScroll()
}

/** 切换未读类型 */
const changeTab = async (index) => {
    tabIndex.value = index
    resetConfig()
    if (tabIndex.value === 0) {
        await getMessageList()
    } else {
        await getUnReadMessageList()
    }
    handleScroll()
}

const getMessageList = () => {
    return new Promise((resolve, reject) => {
        if (readTotal.value > 0 && readPage.value > Math.ceil(readTotal.value / 20)) {
            loading.value = false
            observer.value.unobserve(loadingRef.value)
            return reject('已经翻到最后一页了')
        }
        api.getMessageList({
            mc_type: typeId.value,
            page: readPage.value,
        }).then(res => {
            list.value = [...list.value, ...res.data]
            readTotal.value = res.total
            readPage.value++
            resolve()
        }).catch(err => {
            reject(err)
        })
    })
}

const getUnReadMessageList = () => {
    return new Promise((resolve, reject) => {
        if (unreadTotal.value > 0 && unreadPage.value > Math.ceil(unreadTotal.value / 20)) {
            loading.value = false
            observer.value.unobserve(loadingRef.value)
            return reject('已经翻到最后一页了')
        }
        api.getUnReadMessageList({
            mc_type: typeId.value,
            page: unreadPage.value,
        }).then(res => {
            const arr = res.data.map(item => {
                item.is_read = 0
                return item
            })
            list.value = [...list.value, ...arr]
            unreadTotal.value = res.total
            unreadPage.value++
            resolve()
        }).catch(err => {
            reject(err)
        })
    })
}

/** 获取对应消息类型的全部数和未读数 */
const getReadAndUnReadTotal = () => {
    api.setMessage({
        type: 4,
        mc_type: typeId.value
    }).then(res => {
        readTotal.value = res.all_count
        unreadTotal.value = res.count
    })
}

/** 滚动加载 */
const handleScroll = () => {
    const el = loadingRef.value
    if (!el) return
    observer.value = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting) {
            if (tabIndex.value === 0) {
                await getMessageList()
            } else {
                await getUnReadMessageList()
            }
        }
    })
    observer.value.observe(el)
}

/** 阅读单条消息 */
const clickMessage = (item) => {
    messageSelected.value = item
    if (item.is_read === 0) {
        api.setMessage({
            type: 3,
            mc_type: typeId.value,
            id: item.id
        }).then(() => {
            item.is_read = 1
            unreadTotal.value--
            typeList.value.forEach(type => {
                if (type.mc_type === typeId.value) {
                    type.num--
                }
            })
            proxy.$bus.emit('getMsgTotal')
        })
    }
}

/** 全部标记为已读 */
const clearAllUnRead = () => {
    if (unreadTotal.value === 0) return
    api.setMessage({
        type: 1,
        mc_type: typeId.value
    }).then(async () => {
        unreadTotal.value = 0
        resetConfig()
        typeList.value.forEach(type => {
            if (type.mc_type === typeId.value) {
                type.num = 0
            }
        })
        if (tabIndex.value === 0) {
            await getMessageList()
        } else {
            await getUnReadMessageList()
        }
        proxy.$bus.emit('getMsgTotal')
    })
}

onMounted(() => {
    // 获取消息类型
    api.setMessage({
        type: 2
    }).then(async res => {
        typeList.value = res.data
        getReadAndUnReadTotal()
        await getMessageList()
        handleScroll()
    })

})

</script>

<style lang="less" scoped>
.notice-manage {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 16px;
    font-size: 14px;
    color: #2b82ff;
}

.left-container {
    width: 344px;
    height: 100%;
    padding-top: 24px;
    box-sizing: border-box;
    border-right: 1px solid #EBEEF5;

    .header {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
            display: flex;
            gap: 24px;

            li {
                position: relative;
                cursor: pointer;
                text-align: center;
                font-size: 16px;
                color: #595959;
                line-height: 24px;
                padding-bottom: 4px;

                &.active {
                    color: #262626;
                    font-weight: 500;
                }

                &.active::after {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    content: '';
                    display: block;
                    width: 60%;
                    height: 2px;
                    background-color: #2B82FF;
                    transition: transform 0.3s ease-in-out;
                }
            }
        }

        i {
            font-size: 20px;
            color: #2B82FF;
            cursor: pointer;
        }
    }

    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-top: 80px;

        .title {
            font-size: 14px;
            color: #8C8C8C;
            line-height: 22px;
            margin-top: 4px;
        }
    }

    .list {
        height: calc(100% - 64px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #d9d9d9;
            border-radius: 8px;
        }

        .item {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 106px;
            padding: 16px 20px 16px 32px;
            cursor: pointer;

            &:hover {
                background: #F5F7FA !important;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 20px;
                width: calc(100% - 40px);
                height: 1px;
                background-color: #EBEEF5;
            }


            .title {
                font-size: 14px;
                color: #262626;
                line-height: 22px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 4px;

                &.active {
                    color: #2B82FF;
                }

                &.active::before {
                    position: absolute;
                    top: 24px;
                    left: 20px;
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: #2B82FF;
                    border-radius: 50%;
                }
            }

            .content {
                font-size: 14px;
                color: #595959;
                line-height: 22px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 4px;
            }

            .time {
                width: 100%;
                text-align: right;
                font-size: 14px;
                color: #8C8C8C;
            }
        }

        .divider {
            display: flex;
            align-items: center;
            gap: 8px;
            box-sizing: border-box;
            width: 100%;
            font-size: 14px;
            color: #8C8C8C;
            margin-top: 30px;
            margin-bottom: 24px;
            padding: 0 20px;

            &:before,
            &:after {
                content: "";
                flex: 1;
                border-bottom: 1px solid #EBEEF5;
            }
        }
    }
}

.right-container {
    flex: 1;
    padding: 32px 24px;

    .select {
        .title {
            font-weight: 600;
            font-size: 18px;
            color: #262626;
            line-height: 26px;
            margin-bottom: 16px;
        }

        .content {
            width: 100%;
            font-size: 14px;
            color: #595959;
            line-height: 22px;
            text-align: justify;
            margin-bottom: 16px;
        }

        .time {
            width: 100%;
            text-align: right;
            font-size: 14px;
            color: #8C8C8C;
        }
    }

    .unselect {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .title {
            font-weight: 500;
            font-size: 16px;
            color: #262626;
            line-height: 24px;
            margin-top: 16px;
            margin-bottom: 8px;
        }

        .content {
            font-size: 14px;
            color: #595959;
            line-height: 24px;
        }
    }
}
</style>