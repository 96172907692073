<template>
    <div class="tab-container">
        <div class="head">
            <div @click="onTabChange(item.mc_type)" v-for="item in typeList"
                :class="['tab-head-item', { 'active-tab-head': item.mc_type === activeIndex }]" :key="item.mc_type">
                <div class="tab-head-left">
                    <span class="tab-text">{{ item.title }}</span>
                </div>
                <div class="tab-head-right" v-show="item.num">
                    <div class="tab-badge">{{ item.num }}</div>
                </div>
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    typeList: {
        type: Array,
        default: () => []
    }
})
const activeIndex = ref(1)
const emit = defineEmits(['change'])

//tab切换
const onTabChange = (id) => {
    activeIndex.value = id
    emit('change', id)
}

defineExpose({
    onTabChange
})
</script>

<style lang="less" scoped>
.tab-container {
    min-height: calc(100vh - 100px);

    .content {
        height: calc(100vh - 148px);
        background-color: #ffffff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
    }

    .head {
        display: flex;
        height: 48px;
        cursor: pointer;

        .active-tab-head {
            background-color: #ffffff !important;
            position: relative;
            box-shadow: none !important;

            .tab-text {
                font-family: PingFang SC-Medium !important;
                font-weight: 600 !important;
                color: #262626 !important;
            }
        }

        .tab-head-item {
            box-sizing: border-box;
            width: 168px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: none;
            background: #FAFAFA;
            box-shadow: 2px 0 12px 0 rgba(0, 0, 0, 0.06);
            border-radius: 4px 4px 0 0;
            margin-right: 8px;
            padding-left: 20px;

            .tab-head-left {
                display: flex;
                align-items: center;
            }

            .tab-text {
                font-family: PingFang SC-Regular;
                font-size: 18px;
                color: #595959;
                line-height: 24px;
            }

            .tab-head-right {
                box-sizing: border-box;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 4px;
                padding-right: 6px;

                .tab-badge {
                    background: #FF4D4F;
                    box-shadow: 0px 0px 0px 1px #FFFFFF;
                    border-radius: 100px;
                    font-size: 12px;
                    color: #FFFFFF;
                    line-height: 20px;
                    text-align: center;
                    padding: 0 8px;
                }
            }
        }
    }
}
</style>