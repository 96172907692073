import axios from '@/request/http'
import base from '@/request/base'

const message = {
  getMessageList(data) {
    return axios.get(`${base.admin_url}/cloud/user/message_list`, { params: data })
  },
  getUnReadMessageList(data) {
    return axios.get(`${base.admin_url}/cloud/user/unread_message_list`, { params: data })
  },
  /** 全部已读/消息类型和未读数/单个已读/全部数和未读数 */
  setMessage(data) {
    return axios.post(`${base.admin_url}/cloud/user/set_message`, data)
  },
}

export default message
