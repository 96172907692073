<template>
  <!-- 工单信息 -->
  <el-row style="margin-bottom: 20px;" class="firstRow">
    <el-col>
      <el-card>
        <ul class=" workOrder">
          <li>
            <i class="iconfont1 el-icon-s-order"></i>
            <div>
              <p class="Text" style="margin:0;">工单总数</p>
              <div class="MainHead">{{ $filters.thousands(orderTotal) }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont1 el-icon-s-opportunity"></i>
            <div>
              <p class="Text" style="margin:0;">解决中工单数</p>
              <div class="MainHead">{{ $filters.thousands(orderHandling) }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont1 el-icon-success"></i>
            <div>
              <p class="Text" style="margin:0;">已解决工单数</p>
              <div class="MainHead">{{ $filters.thousands(orderResolved) }}</div>
            </div>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>

  <!-- 问题反馈 -->
  <el-row>
    <el-col>
      <el-card class="box-card" style="min-height: 800px">
        <template #header>
          <div class="card-header">
            <span class="SubHead">反馈详情</span>
            <el-link>
              <router-link to="feedback"><span class="LinkText">新增反馈</span></router-link>
            </el-link>
          </div>
        </template>
        <el-form ref="workOrderFormRef" :inline="true" :model="workOrderForm">
          <el-form-item label="工单编号" prop="orderNum">
            <el-input
              placeholder="请输入工单编号"
              size="small"
              clearable
              v-model.trim="workOrderForm.orderNum"
              style="width:220px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="问题类型" prop="type">
            <el-select v-model="workOrderForm.type" placeholder="请选择问题类型" size="small">
              <el-option label="产品建议" value="产品建议"></el-option>
              <el-option label="功能缺陷" value="功能缺陷"></el-option>
              <el-option label="广告问题" value="广告问题"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="问题内容" prop="problemContent">
            <el-input
              placeholder="请输入问题内容"
              size="small"
              clearable
              v-model.trim="workOrderForm.problemContent"
              style="width:220px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="state">
            <el-select v-model="workOrderForm.state" placeholder="请选择状态" size="small">
              <el-option label="已解决" value="1"></el-option>
              <el-option label="解决中" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 9px;">
            <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
            <el-button @click="resetFormBtn" size="small">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="feedbackDetailRef"
          :data="feedbackDetail"
          style="width: 100%"
          :header-cell-style="{
            background: 'var(--el-table-row-hover-background-color)'
          }"
          :row-style="{
            cursor: 'pointer'
          }"
          :row-key="getRowId"
          :row-class-name="getRowClass"
          @row-click="rowClick"
          @expand-change="changeTips"
        >
          <el-table-column label="工单编号" width="150">
            <template #default="{ row }">
              <i
                class="iconfont icon-yldian"
                :class="row.is_show == 0 ? 'new ' : ' old'"
                v-show="row.gm_content != null"
              ></i>
              <span :class="row.gm_content != null ? '' : 'workNum'">
                {{ row.work_no }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="问题类型" prop="type" width="150" />
          <el-table-column label="功能版块" prop="function" width="150" />
          <el-table-column label="问题内容" prop="content" width="350" />
          <el-table-column label="提交时间" prop="" width="200">
            <template #default="{ row }">
              {{ row.create_at }}
            </template>
          </el-table-column>
          <el-table-column label="解决时间" prop="gm_at" width="200" />
          <el-table-column label="备注" column-key="img">
            <template #default="{ row }">
              <div v-if="row.url != ''">
                <el-image style="width: 50px; height: 50px" :src="row.url" :preview-src-list="row.urls" @click.stop="">
                </el-image>
              </div>
              <span v-else>未上传截图</span>
            </template>
          </el-table-column>
          <el-table-column label="工单状态" fixed="right" width="175">
            <template #default="props">
              <el-tag type="success" v-if="props.row.gm_content">已解决</el-tag>
              <el-tag type="warning" v-else>解决中</el-tag>
            </template>
          </el-table-column>
          <el-table-column type="expand" fixed="right" width="40px">
            <template #default="props">
              <el-row align="center">
                <el-col :span="20">
                  <p style="padding-left: 15px">YinoLink回复：<span v-html="getHref(props.row.gm_content)"></span></p
                ></el-col>
                <el-col :span="4" style="display: flex;align-items: center;justify-content: center;">
                  <div class="isResolve" v-if="props.row.status == 0" style="padding-left:35px;">
                    <el-button size="mini" type="primary" plain @click="resolvedBtn(props.row)"
                      ><i class="el-icon-check"></i> 已解决</el-button
                    >
                    <el-button size="mini" type="warning" plain @click="unsolvedBtn(props.row)"
                      ><i class="el-icon-close"></i> 未解决</el-button
                    >
                  </div>
                  <div v-else>
                    <el-tag class="resolveTag" type="success" style="margin-left:14px;">评价成功，感谢您的支持!</el-tag>
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
          </template>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination
          v-model:currentPage="queryInfo.page"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          :pager-count="5"
          :background="true"
        >
        </el-pagination>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'FeedBackDetail',
  data() {
    return {
      orderTotal: 0,
      orderHandling: 0,
      orderResolved: 0,
      isExpand: true,
      feedbackDetail: [],
      // 获取反馈列表的参数对象
      queryInfo: {
        query: '',
        // 当前的页数
        page: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      total: 0,
      msgTotal: 0,
      remark: 0,
      workOrderForm: {
        orderNum: '',
        problemContent: '',
        type: '',
        state: ''
      }
    }
  },
  mounted() {
    this.getFeedBackInfo()
  },
  methods: {
    getHref(content) {
      if (!content) {
        return ''
      }
      const urlPattern = /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[()~#&\-=?+%/.\w]+)?/g
      content = content.replace(urlPattern, match => {
        const urlHttp = match.indexOf('http://') && match.indexOf('https://')
        const url = urlHttp === -1 ? match.split('/')[0] : match.split('/')[2]
        const href = urlHttp === -1 ? `https://${match}` : match
        return `<a target="_blank" href="${href}" style="color:#2b82ff;">${href}</a>`
      })
      return content
    },
    async getFeedBackInfo() {
      const { data: res } = await this.$http.post('user/feedback_detail', {
        page: this.queryInfo.page,
        work_no: this.workOrderForm.orderNum,
        keyword: this.workOrderForm.problemContent,
        type: this.workOrderForm.type,
        status: this.workOrderForm.state
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.feedbackDetail = res.data
      this.total = res.total
      this.orderTotal = res.total_num
      this.orderHandling = res.solving_num
      this.orderResolved = res.solved_num
    },
    getRowId(row) {
      return row.id
    },
    async rowClick(row, column) {
      if (row.gm_content == null) {
        return false
      }
      this.isExpand = false
      this.$refs.feedbackDetailRef.toggleRowExpansion(row)
      if (row.is_show == 0) {
        const { data: res } = await this.$http.post('user/feedback_detail', {
          type: 1,
          id: row.id
        })
        row.is_show = 1
        this.msgTotal = res.total
        this.isExpand = true
      }
    },
    async changeTips(row) {
      if (row.is_show == 0 && this.isExpand !== false) {
        const { data: res } = await this.$http.post('user/feedback_detail', {
          type: 1,
          id: row.id
        })
        row.is_show = 1
        this.msgTotal = res.total
      }
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPages) {
      this.queryInfo.page = newPages
      this.getFeedBackInfo()
    },
    // 无回复时隐藏展开图标
    getRowClass(row) {
      if (row.row.gm_content == null) {
        return 'row-expand-cover'
      }
    },
    async resolvedBtn(row) {
      const { data: res } = await this.$http.post('user/feedback', {
        id: row.id,
        type: 1,
        status: 1
      })
      if (res.code == 200) {
        row.status = 1
      }
    },
    async unsolvedBtn(row) {
      const { data: res } = await this.$http.post('user/feedback', {
        id: row.id,
        type: 1,
        status: 2
      })
      if (res.code == 200) {
        row.status = 2
      }
    },
    // 查询按钮
    async queryFormBtn() {
      this.$refs.workOrderFormRef.validate(valid => {
        if (!valid) return
        this.getFeedBackInfo()
      })
    },
    // 重置按钮
    resetFormBtn() {
      this.$refs.workOrderFormRef.resetFields()
      this.getFeedBackInfo()
    }
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.workOrder {
  display: flex;
  justify-content: space-evenly;
  li {
    position: relative;
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 1px solid #d9d9d9;
    i.iconfont1 {
      font-size: 50px;
      color: #2b82ff;
    }
    div {
      width: 50%;
    }
  }
  li:last-child {
    border: none;
  }
}
i.icon-yldian.new {
  color: #2b82ff;
}
i.icon-yldian.old {
  color: #ccc;
}
:deep(.el-table .row-expand-cover .cell .el-table__expand-icon) {
  display: none;
}
.firstRow {
  :deep(.el-card__body) {
    padding: 36px 20px;
  }
}
.workNum {
  padding-left: 18px;
}
@media screen and (max-width: 1815px) {
  .isResolve {
    padding-left: 20px !important;
  }
  .resolveTag {
    margin-left: 0 !important;
  }
}
</style>
