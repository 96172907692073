<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">通知管理</span>
        <!-- <el-button type="text" style="min-height:auto;padding: 0;">恢复默认</el-button> -->
      </div>
    </template>
    <!-- <el-scrollbar height="580px"> -->
    <el-table ref="tableRef" :data="tableData" style="width: 100%" :default-expand-all="true" :header-cell-style="{
      background: 'var(--el-table-row-hover-background-color)'
    }" row-key="id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      >
      <el-table-column width="50" type="">
        <template #header>
          <el-checkbox v-model="selectAll" :indeterminate="isSelectedAll" @change="triggerSelectedAll"></el-checkbox>
        </template>
        <template #default="scope">
          <el-checkbox-group v-model="selections">
            <el-checkbox v-if="scope.row.children && scope.row.children.length > 0" :label="scope.row.function"
              :indeterminate="isSelected[scope.row.function]" @change="changeSelection(scope.row)"></el-checkbox>
            <el-checkbox :label="scope.row.function" @change="changeSelection(scope.row)" v-else></el-checkbox>
          </el-checkbox-group>
        </template>
      </el-table-column>

      <el-table-column property="name" label="通知类型" min-width="220" />
      <el-table-column min-width="240">
        <template #default="{ row }">
          <div v-if="row.name === '余额不足提醒'" style="font-size: 12px;">
            余额不足<el-input-number v-model="num" :min="1" step-strictly size="mini" @change="changeDayNum"
              style="margin: 0 4px;" />天消耗时提醒
          </div>
          <div v-if="row.name === '盗刷提醒'" style="font-size: 12px;">
            若最新30min内，主体维度的消耗大于昨日当前主体消耗的<el-input-number v-model="stealingPercent" :min="1" :max="100" step-strictly
              size="mini" @change="changeStealingPercent" style="margin: 0 4px;" />% 且 一小时内消耗大于$<el-input-number
              v-model="stealingSpent" :min="1" step-strictly size="mini" @change="changeStealingSpent"
              style="margin: 0 4px;width: 100px;" />则发送提醒
          </div>
        </template>
      </el-table-column>
      <el-table-column label="微信" min-width="150">
        <template #header>
          <div class="customHead">
            <el-checkbox :true-label="1" :indeterminate="isWxIndeterminate" :false-label="0" v-model="Alltype.wx_type"
              @change="changeAllWX"></el-checkbox>
            <span>微信</span>
          </div>
        </template>
        <template #default="{ row, column }">
          <el-checkbox :true-label="1" :false-label="0" :disabled="row.name === '盗刷提醒'" v-model="row.wx_type"
            @change="changeWeChat(row, column)" size="large" />
        </template>
      </el-table-column>
      <el-table-column label="短信" min-width="150">
        <template #header>
          <div class="customHead">
            <el-checkbox :true-label="1" :false-label="0" :indeterminate="isPhoneIndeterminate"
              v-model="Alltype.phone_type" @change="changeAllPhone"></el-checkbox>
            <span>短信</span>
          </div>
        </template>
        <template #default="{ row, column }">
          <el-checkbox :true-label="1" :false-label="0" v-model="row.phone_type" label=""
            :disabled="row.bang_phone == 1" @change="changeTextMsg(row, column)" size="large" />
        </template>
      </el-table-column>
      <el-table-column label="邮件" min-width="150">
        <template #header>
          <div class="customHead">
            <el-checkbox :true-label="1" :false-label="0" :indeterminate="isEmailIndeterminate"
              v-model="Alltype.email_type" @change="changeAllEmail"></el-checkbox>
            <span>邮件</span>
          </div>
        </template>
        <template #default="{ row, column }">
          <el-checkbox :true-label="1" :false-label="0" v-model="row.email_type" label=""
            @change="changeEmail(row, column)" size="large" />
        </template>
      </el-table-column>
      <el-table-column label="消息接收人" min-width="150">
        <template #default="{ row }">
          <div v-show="row.type == 1">
            <span style="font-size:12px;">{{ row.n_name }}</span>
          </div>
          <el-button type="text" size="small" v-show="row.type == 1" @click="editRowContact(row.id)"><span
              class="brandColor">修改</span></el-button>
        </template>
      </el-table-column>
      <template #empty>
        <img src="@/assets/empty.gif" alt="" style="width:400px;height:400px" />
      </template>
    </el-table>
    <!-- </el-scrollbar> -->
    <!-- <div style="margin-top: 20px">
      <el-button type="primary" size="small" :disabled="selections.length == 0" @click="BatchAdd()"
        >批量修改消息接收人</el-button
      >
    </div> -->
  </el-card>

  <!-- 修改消息接收人对话框 -->
  <el-dialog v-model="contactsFormDialogVisible" @close="contactsFormClose" title="修改消息联系人" width="800px"
    custom-class="subDialog">
    <el-alert title="" type="info" :closable="false" show-icon>
      <template #default>
        请在下方新增您想要添加的联系人
      </template>
    </el-alert>
    <el-table :header-cell-style="{
      background: 'var(--el-table-row-hover-background-color)'
    }" :data="contactsTableData" style="width: 100%">
      <el-table-column prop="type" label="" width="50">
        <template #default="{ row }">
          <el-checkbox :true-label="1" :false-label="0" v-model="row.is_check" size="large" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="email" label="邮箱" />
      <el-table-column prop="phone" label="手机" />
      <el-table-column label="操作" width="150">
        <template #default="{ row }">
          <el-popconfirm title="确定删除当前联系人吗？" @confirm="deleteContact(row)">
            <template #reference>
              <el-button type="text" size="small" v-show="row.type == 0"><span
                  style="color:#F56C6C;">删除</span></el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加联系人 -->
    <el-form class="addContactForm" ref="addContactFormRef" :rules="addContactFormRules" :inline="true"
      :model="addContactForm" v-if="isAddShow == 1">
      <el-form-item label="" prop="name">
        <el-input v-model="addContactForm.name" placeholder="姓名" size="small" clearable />
      </el-form-item>
      <el-form-item label="" prop="email">
        <el-input v-model="addContactForm.email" placeholder="邮箱" size="small" clearable />
      </el-form-item>
      <el-form-item label="" prop="phone">
        <el-input v-model="addContactForm.phone" placeholder="手机" size="small" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="text" @click="onAdd">确定</el-button>
        <el-button type="text" @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
    <el-button type="text" size="small" class="addTableBtn" @click="addTableBtn"
      v-else><span>+新增消息联系人</span></el-button>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="contactsFormCancelBtn">取消</el-button>
        <el-button size="small" type="primary" @click="contactsFormConfirmBtn">保存</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 绑定微信号对话框 -->
  <div class="bindWeChat">
    <el-dialog v-model="bindWeChatDialogVisible" :close-on-click-modal="false" :close-on-press-escape="false"
      title="绑定微信号" width="20%">
      <p class="Text" style="text-align: center">绑定后可微信登录<br /></p>
      <div style="text-align: center">
        <el-image style="width: 200px; height: 200px" :src="wxUrl" fit="fill"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setStealingRemind } from '@/request/api/common'

export default {
  name: 'NoticeManager',
  data() {
    // 邮箱集验证规则
    const emailValidRule = (rule, value, cb) => {
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

      if (!reg.test(value)) {
        cb(new Error('邮箱格式不正确'))
      }
      return cb()
    }
    // 手机号验证规则
    const phoneValidRule = (rule, value, cb) => {
      let reg = /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/
      // value.forEach(item => {
      //   if (!reg.test(item)) {
      //     cb(new Error('手机号码不正确'))
      //   }
      // })
      if (!reg.test(value)) {
        cb(new Error('手机号码不正确'))
      }
      return cb()
    }
    return {
      selectAll: false,
      isSelectedAll: false,
      selections: [],
      isSelected: {},
      allItems: [],
      formattedData: {},
      Alltype: {},
      contactTitle: '',
      contactTitle1: '',
      tableData: [],
      selectArr: [],
      rowType: '',
      rowFunction: '',
      contactsFormDialogVisible: false,
      contactsTableData: [],
      addContactForm: {
        name: '',
        email: '',
        phone: ''
      },
      addContactFormRules: {
        name: [{ required: true, message: '请输入名字', trigger: 'blur' }],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: emailValidRule, trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机', trigger: 'blur' },
          { validator: phoneValidRule, trigger: 'change' }
        ]
      },
      isAddShow: 0,
      n_id: 0,
      bindWeChatDialogVisible: false, // 绑定微信号对话框
      wxUrl: '',
      wxKey: '',
      wxClock: undefined,
      num: 3, // 余额不足几天消耗时提醒
      stealingPercent: 15, // 盗刷提醒百分比
      stealingSpent: 1000, // 盗刷提醒消耗金额
      isWxIndeterminate: false,
      isPhoneIndeterminate: false,
      isEmailIndeterminate: false,
    }
  },
  mounted() {
    this.getContactsInfo()
    this.getStealingRemind()
  },
  methods: {
    initFormattedData(data) {
      data.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.isSelected[item.function] = false
          item.children.forEach(child => {
            child.parent_id = item.function
            this.allItems.push(child.function)
          })
        }
        this.formattedData[item.function] = item
        this.allItems.push(item.function)
      })
      return data
    },
    async getContactsInfo() {
      const { data: res } = await this.$http.post('user/notice')
      console.log(res)
      this.Alltype = res.all
      let data = this.initFormattedData(res.data)
      this.tableData = data
      this.num = res.num
    },
    getStealingRemind() {
      setStealingRemind({
        type: 1
      }).then(res => {
        this.stealingPercent = parseInt(res.percent)
        this.stealingSpent = parseInt(res.spent)
      })
    },
    getRowId(row) {
      return row.id
    },
    async changeAllWX() {
      if (!this.$store.getters.userInfo.w_openid) {
        this.userId = this.$store.getters.userInfo.id
        this.wxBindImg()
        this.bindWeChatDialogVisible = true
        this.Alltype.wx_type = 0
        return
      }
      if (this.Alltype.wx_type == 1) {
        const confirmResult = await this.$confirm(`确定要批量开启微信通知吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm') {
          return (this.Alltype.wx_type = 0)
        } else {
          this.isWxIndeterminate = false
          const { data: res } = await this.$http.post('user/set_notice', {
            function: 'all',
            check: this.Alltype.wx_type,
            type: 'wx_type'
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      } else {
        const confirmResult = await this.$confirm(
          `确定要批量关闭微信通知吗？建议您务必接收，防止消息遗漏造成损失。`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).catch(err => err)

        if (confirmResult !== 'confirm') {
          return (this.Alltype.wx_type = 1)
        } else {
          this.isWxIndeterminate = false
          const { data: res } = await this.$http.post('user/set_notice', {
            function: 'all',
            check: this.Alltype.wx_type,
            type: 'wx_type'
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      }
    },
    async changeAllPhone() {
      if (this.Alltype.phone_type == 1) {
        const confirmResult = await this.$confirm(`确定要批量开启短信通知吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm') {
          return (this.Alltype.phone_type = 0)
        } else {
          this.isPhoneIndeterminate = false
          const { data: res } = await this.$http.post('user/set_notice', {
            function: 'all',
            check: this.Alltype.phone_type,
            type: 'phone_type'
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      } else {
        const confirmResult = await this.$confirm(
          `确定要批量关闭短信通知吗？建议您务必接收，防止消息遗漏造成损失。`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).catch(err => err)

        if (confirmResult !== 'confirm') {
          return (this.Alltype.phone_type = 1)
        } else {
          this.isPhoneIndeterminate = false
          const { data: res } = await this.$http.post('user/set_notice', {
            function: 'all',
            check: this.Alltype.phone_type,
            type: 'phone_type'
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      }
    },
    async changeAllEmail() {
      if (this.Alltype.email_type == 1) {
        const confirmResult = await this.$confirm(`确定要批量开启邮件通知吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm') {
          return (this.Alltype.email_type = 0)
        } else {
          this.isEmailIndeterminate = false
          const { data: res } = await this.$http.post('user/set_notice', {
            function: 'all',
            check: this.Alltype.email_type,
            type: 'email_type'
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      } else {
        const confirmResult = await this.$confirm(
          `确定要批量关闭邮件通知吗？建议您务必接收，防止消息遗漏造成损失。`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).catch(err => err)

        if (confirmResult !== 'confirm') {
          return (this.Alltype.email_type = 1)
        } else {
          this.isEmailIndeterminate = false
          const { data: res } = await this.$http.post('user/set_notice', {
            function: 'all',
            check: this.Alltype.email_type,
            type: 'email_type'
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      }
    },
    // 行内的微信
    async changeWeChat(row, column) {
      if (!this.$store.getters.userInfo.w_openid) {
        this.userId = this.$store.getters.userInfo.id
        this.wxBindImg()
        this.bindWeChatDialogVisible = true
        row.wx_type = 0
        return
      }
      if (row.hasOwnProperty('children')) {
        if (row.wx_type == 1) {
          const confirmResult = await this.$confirm(`确定要批量开启“${row.name}”${column.label}通知吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => err)

          if (confirmResult !== 'confirm') {
            return (row.wx_type = 0)
          } else {
            row.wx_type = 1
            this.handleIndeterminate('wx')
            if (column.label == '微信') {
              var wx = 'wx_type'
            }

            const { data: res } = await this.$http.post('user/set_notice', {
              function: row.function,
              check: row.wx_type,
              type: wx
            })
            if (res.code == 200) {
              this.getContactsInfo()
            }
          }
        } else {
          const confirmResult = await this.$confirm(
            `确定要批量关闭“${row.name}”${column.label}通知吗？建议您务必接收，防止消息遗漏造成损失。`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).catch(err => err)

          if (confirmResult !== 'confirm') {
            return (row.wx_type = 1)
          } else {
            row.wx_type = 0
            this.handleIndeterminate('wx')
            if (column.label == '微信') {
              var wx = 'wx_type'
            }
            const { data: res } = await this.$http.post('user/set_notice', {
              function: row.function,
              check: row.wx_type,
              type: wx
            })
            if (res.code == 200) {
              this.getContactsInfo()
            }
          }
        }
      } else {
        if (row.wx_type == 1) {
          row.wx_type = 1
          this.handleIndeterminate('wx')
          if (column.label == '微信') {
            var wx = 'wx_type'
          }

          const { data: res } = await this.$http.post('user/set_notice', {
            function: row.function,
            check: row.wx_type,
            type: wx
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        } else {
          row.wx_type = 0
          this.handleIndeterminate('wx')
          if (column.label == '微信') {
            var wx = 'wx_type'
          }
          const { data: res } = await this.$http.post('user/set_notice', {
            function: row.function,
            check: row.wx_type,
            type: wx
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      }
    },
    // 行内的短信
    async changeTextMsg(row, column) {
      if (row.hasOwnProperty('children')) {
        if (row.phone_type == 1) {
          const confirmResult = await this.$confirm(`确定要批量开启“${row.name}”${column.label}通知吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => err)

          if (confirmResult !== 'confirm') {
            return (row.phone_type = 0)
          } else {
            row.phone_type = 1
            this.handleIndeterminate('phone')
            if (column.label == '短信') {
              var phone = 'phone_type'
            }

            const { data: res } = await this.$http.post('user/set_notice', {
              function: row.function,
              check: row.phone_type,
              type: phone
            })
            if (res.code == 200) {
              this.getContactsInfo()
            }
          }
        } else {
          const confirmResult = await this.$confirm(
            `确定要批量关闭“${row.name}”${column.label}通知吗？建议您务必接收，防止消息遗漏造成损失。`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).catch(err => err)

          if (confirmResult !== 'confirm') {
            return (row.phone_type = 1)
          } else {
            row.phone_type = 0
            this.handleIndeterminate('phone')
            if (column.label == '短信') {
              var phone = 'phone_type'
            }
            const { data: res } = await this.$http.post('user/set_notice', {
              function: row.function,
              check: row.phone_type,
              type: phone
            })
            if (res.code == 200) {
              this.getContactsInfo()
            }
          }
        }
      } else {
        if (row.phone_type == 1) {
          row.phone_type = 1
          this.handleIndeterminate('phone')
          if (column.label == '短信') {
            var phone = 'phone_type'
          }

          const { data: res } = await this.$http.post('user/set_notice', {
            function: row.function,
            check: row.phone_type,
            type: phone
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        } else {
          row.phone_type = 0
          this.handleIndeterminate('phone')
          if (column.label == '短信') {
            var phone = 'phone_type'
          }
          const { data: res } = await this.$http.post('user/set_notice', {
            function: row.function,
            check: row.phone_type,
            type: phone
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      }
    },
    // 行内的邮件
    async changeEmail(row, column) {
      if (row.hasOwnProperty('children')) {
        if (row.email_type == 1) {
          const confirmResult = await this.$confirm(`确定要批量开启“${row.name}”${column.label}通知吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => err)

          if (confirmResult !== 'confirm') {
            return (row.email_type = 0)
          } else {
            row.email_type = 1
            this.handleIndeterminate('email')
            if (column.label == '邮件') {
              var email = 'email_type'
            }

            const { data: res } = await this.$http.post('user/set_notice', {
              function: row.function,
              check: row.email_type,
              type: email
            })
            if (res.code == 200) {
              this.getContactsInfo()
            }
          }
        } else {
          const confirmResult = await this.$confirm(
            `确定要批量关闭“${row.name}”${column.label}通知吗？建议您务必接收，防止消息遗漏造成损失。`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).catch(err => err)

          if (confirmResult !== 'confirm') {
            return (row.email_type = 1)
          } else {
            row.email_type = 0
            this.handleIndeterminate('email')
            if (column.label == '邮件') {
              var email = 'email_type'
            }
            const { data: res } = await this.$http.post('user/set_notice', {
              function: row.function,
              check: row.email_type,
              type: email
            })
            if (res.code == 200) {
              this.getContactsInfo()
            }
          }
        }
      } else {
        if (row.email_type == 1) {
          row.email_type = 1
          this.handleIndeterminate('email')
          if (column.label == '邮件') {
            var email = 'email_type'
          }

          const { data: res } = await this.$http.post('user/set_notice', {
            function: row.function,
            check: row.email_type,
            type: email
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        } else {
          row.email_type = 0
          this.handleIndeterminate('email')
          if (column.label == '邮件') {
            var email = 'email_type'
          }
          const { data: res } = await this.$http.post('user/set_notice', {
            function: row.function,
            check: row.email_type,
            type: email
          })
          if (res.code == 200) {
            this.getContactsInfo()
          }
        }
      }
    },
    contactsFormClose() {
      this.isAddShow = 0
    },
    // 每行单独修改联系人
    async editRowContact(id) {
      this.contactsFormDialogVisible = true
      const { data: res } = await this.$http.post('user/get_connect', {
        id: id
      })
      console.log(res)
      if (res.code == 200) {
        this.contactsTableData = res.data
        this.n_id = id
      }
    },
    // 批量添加
    async BatchAdd() {
      var selections = Array.prototype.slice.call(this.selections)
      console.log(selections)
      //  const {data:res} = await this.$http.post('',{
      //     function: selections,
      // })
      this.contactsFormDialogVisible = true
    },
    // 修改对话框
    contactsFormCancelBtn() {
      this.contactsFormDialogVisible = false
    },
    async contactsFormConfirmBtn() {
      var checkRow = []
      this.contactsTableData.filter(item => {
        if (item.is_check == 1) {
          checkRow.push(item.id)
        }
      })
      const { data: res } = await this.$http.post('user/set_connect', {
        type: 2,
        id: checkRow,
        n_id: this.n_id
      })
      if (res.code == 200) {
        this.getContactsInfo()
      }

      this.contactsFormDialogVisible = false
    },
    addTableBtn() {
      this.isAddShow = 1
    },
    onCancel() {
      this.$refs.addContactFormRef.resetFields()
      this.isAddShow = 0
    },
    onAdd() {
      this.$refs.addContactFormRef.validate(async vaild => {
        if (!vaild) return
        const { data: res } = await this.$http.post('user/set_connect', {
          type: 1,
          name: this.addContactForm.name,
          email: this.addContactForm.email,
          phone: this.addContactForm.phone
        })

        if (res.code == 200) {
          this.contactsTableData.push({
            id: res.id,
            is_check: 0,
            type: 0,
            name: this.addContactForm.name,
            email: this.addContactForm.email,
            phone: this.addContactForm.phone
          })
          this.$refs.addContactFormRef.resetFields()
          this.isAddShow = 0
        }
      })
    },
    async deleteContact(row) {
      // if(row.is_check == 1) {
      //   return this.message.warning('')
      // }
      const { data: res } = await this.$http.post('user/set_connect', {
        type: 3,
        id: row.id
      })
      if (res.code == 200) {
        this.editRowContact(row.id)
      }
    },
    // -----------处理多选--------------
    triggerSelectedAll(val) {
      if (!this.allItems.length) {
        this.selectAll = false
        this.isSelectedAll = false
        return
      }

      // console.log(val, selectAll.value, this.selections.length, allItems.value.length)
      if (undefined !== val) {
        this.selections = val ? this.allItems : []
      } else {
        this.selectAll = this.selections.length === this.allItems.length
      }

      this.isSelectedAll = this.selections.length > 0 && this.selections.length < this.allItems.length
    },
    changeSelection(row) {
      if (!row.parent_id) {
        //父节点操作
        if (row.children && row.children.length > 0) {
          row.children.forEach(child => {
            //勾选父节点下所有节点
            if (this.selections.indexOf(row.function) >= 0 && this.selections.indexOf(child.function) < 0)
              this.selections.push(child.function)
            //删除勾选父节点下所有节点
            if (this.selections.indexOf(row.function) < 0 && this.selections.indexOf(child.function) >= 0)
              this.selections.splice(this.selections.indexOf(child.function), 1)
          })
        }
      } else {
        //子节点操作
        //获取父节点，若初始化没有formattedDate 也可以遍历tableData寻找父节点
        let parent = this.formattedData[row.parent_id]
        if (undefined !== parent && parent.children && parent.children.length > 0) {
          //更新选中数据
          let selectedCount = 0
          parent.children.forEach(child => {
            if (this.selections.indexOf(child.function) >= 0) selectedCount += 1
          })

          this.isSelected[parent.function] = selectedCount > 0 && selectedCount < parent.children.length
          //子节点全选，父节点选中
          if (selectedCount === parent.children.length && this.selections.indexOf(parent.function) < 0)
            this.selections.push(parent.function)
          //子节点全未选，父节点删除选中
          if (0 === selectedCount && this.selections.indexOf(parent.function) >= 0)
            this.selections.splice(this.selections.indexOf(parent.function), 1)
        }
      }
      this.triggerSelectedAll()
    },
    // 获取绑定微信二维码
    async wxBindImg() {
      const { data: res } = await this.$http.get('login/wxImg')
      this.wxUrl = res.data.url
      this.wxKey = res.data.key
      this.wxBindLogin()
    },
    // 绑定微信登录
    wxBindLogin() {
      this.wxClock = window.setInterval(async () => {
        const { data: res } = await this.$http.post('login/edit_whchat', {
          titcket: this.wxKey,
          user_id: this.userId,
          type: 3,
        })
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          clearInterval(this.wxClock)
        }
        if (res.code == 200) {
          clearInterval(this.wxClock)
          this.$message.success('绑定成功')
          this.bindWeChatDialogVisible = false
        }
      }, 1000)
    },
    async changeDayNum() {
      const { data: res } = await this.$http.post('user/set_day_num', {
        day: this.num
      })
      if (res.code == 200) {
        this.$message.success(res.msg)
      }
    },
    changeStealingPercent() {
      setStealingRemind({
        percent: this.stealingPercent,
        spent: this.stealingSpent,
        type: 2
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
        }
      })
    },
    changeStealingSpent() {
      setStealingRemind({
        percent: this.stealingPercent,
        spent: this.stealingSpent,
        type: 2
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
        }
      })
    },
    handleIndeterminate(type) {
      if (type === 'wx') {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].wx_type === 1) {
            this.isWxIndeterminate = true
            return
          }
          if (this.tableData[i].children && this.tableData[i].children.length > 0) {
            for (let j = 0; j < this.tableData[i].children.length; j++) {
              if (this.tableData[i].children[j].wx_type === 1) {
                this.isWxIndeterminate = true
                return
              }
            }
          }
        }
        this.isWxIndeterminate = false
      } else if (type === 'phone') {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].phone_type === 1) {
            this.isPhoneIndeterminate = true
            return
          }
          if (this.tableData[i].children && this.tableData[i].children.length > 0) {
            for (let j = 0; j < this.tableData[i].children.length; j++) {
              if (this.tableData[i].children[j].phone_type === 1) {
                this.isPhoneIndeterminate = true
                return
              }
            }
          }
        }
        this.isPhoneIndeterminate = false
      } else if (type === 'email') {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].email_type === 1) {
            this.isEmailIndeterminate = true
            return
          }
          if (this.tableData[i].children && this.tableData[i].children.length > 0) {
            for (let j = 0; j < this.tableData[i].children.length; j++) {
              if (this.tableData[i].children[j].email_type === 1) {
                this.isEmailIndeterminate = true
                return
              }
            }
          }
        }
        this.isEmailIndeterminate = false
      }
    },
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.el-table__expanded-cell) {
  padding: 0 48px 0 0;
  border-bottom: none !important;
}

:deep(.el-table__indent) {
  padding-left: 0 !important;
}

:deep(.el-table tr.expanded) {
  background-color: #f5f7fa;
}

:deep(.el-table__body .el-table__row.hover-row td) {
  // background-color: transparent;
  background-color: #f9f9fa;
}

.customHead {
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
}

// 全选区域
:deep(.el-checkbox__label) {
  display: none !important;
}

.addTableBtn {
  color: #2b82ff;
  margin: 12px 0;
}

.addContactForm {
  margin: 12px 0;

  .el-form-item {
    margin-bottom: 0;
  }

  .el-input {
    width: 190px;
  }
}

:deep(.el-input-number--mini) {
  width: 70px;
}

:deep(.el-input-number__decrease) {
  height: 22px;
  line-height: 22px;
  width: 20px;
  top: 3px;
}

:deep(.el-input-number__increase) {
  height: 22px;
  line-height: 22px;
  width: 20px;
  top: 3px;
}

:deep(.el-input-number--mini .el-input__inner) {
  height: 24px;
  line-height: 24px;
  padding: 0 24px;
}
</style>
