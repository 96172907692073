<template>
  <el-container>
    <el-header>
      <div>
        <img src="@/assets/ylcloudlogo.svg" alt="" class="largeImg" />
      </div>
    </el-header>
    <el-main class="psdBox" style="padding-top:4%;">
      <el-row :gutter="20" justify="space-around" style="width: 95%">
        <el-col :span="10">
          <el-card class="editPsdBox">
            <el-steps :active="activeIndex" finish-status="success" align-center>
              <!-- <el-step title="确认账号">11</el-step> -->
              <el-step title="验证账号"></el-step>
              <el-step title="重置密码"></el-step>
            </el-steps>

            <!-- 验证账号表单 -->
            <el-form ref="accountNumFormRef" :model="accountNumForm" :rules="accountNumFormRules" label-position="left"
              label-width="100px" v-show="activeIndex == 0">
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="accountNumForm.phone" clearable size="small" :validate-event="false"></el-input>
              </el-form-item>
              <el-form-item label="图形验证码" prop="identifyCode">
                <el-row :gutter="10">
                  <el-col :span="15">
                    <el-input v-model="accountNumForm.identifyCode" placeholder="请输入图形验证码" size="small" clearable
                      :validate-event="false"></el-input>
                  </el-col>
                  <el-col :span="9">
                    <div @click="refreshCode">
                      <Sidentify :identifyCode="identifyCode"></Sidentify>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="手机验证码" prop="phoneCode">
                <el-row :gutter="10">
                  <el-col :span="15">
                    <el-input v-model="accountNumForm.phoneCode" placeholder="请输入手机验证码" size="small" clearable
                      :validate-event="false"></el-input>
                  </el-col>
                  <el-col :span="9">
                    <el-button size="small" :disabled="!rightPhone" @click="getCode">
                      {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
                    </el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="accountNumBtn" size="small">下一步</el-button>
              </el-form-item>
            </el-form>

            <!-- 确认密码表单 -->
            <el-form ref="confirmPsdFormRef" :model="confirmPsdForm" :rules="confirmPsdFormRules" label-position="left"
              label-width="80px" v-show="activeIndex == 1">
              <el-form-item label="新密码" prop="newPsd">
                <el-input type="password" v-model="confirmPsdForm.newPsd" placeholder="请输入新密码" size="small" clearable
                  show-password :validate-event="false"></el-input>
              </el-form-item>
              <el-form-item label="再次确认" prop="newPsddb">
                <el-input v-model="confirmPsdForm.newPsddb" type="password" placeholder="请再次输入新密码" size="small"
                  clearable show-password :validate-event="false"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="confirmPsdBtn" size="small">提交</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
        <el-col :span="8"><img src="@/assets/bg_1-1.png" alt="" class="bgImg" /></el-col>
      </el-row>
    </el-main>
    <el-footer>
      <el-link :underline="false" href="https://beian.miit.gov.cn" target="_blank"
        style="width: 100%; text-align: center">
        <p class="Text">
          杭州益刻网络科技有限公司版权所有Copyright © 2020
          <span><img src="@/assets/officeIcon.png" alt="" /></span>浙ICP备20011628号-1
        </p>
      </el-link>
    </el-footer>
  </el-container>
</template>

<script>
import Sidentify from './identify.vue'
export default {
  name: 'ForgetPsd',
  components: { Sidentify },
  data() {
    return {
      userInfo: '',
      activeIndex: 0,
      accountNumForm: {
        phone: '',
        identifyCode: '',
        phoneCode: ''
      },
      accountNumFormRules: {
        phone: [{ required: true, message: '请输入手机号' }],
        identifyCode: [{ required: true, message: '请输入图形验证码' }],
        phoneCode: [{ required: true, message: '请输入手机验证码' }]
      },
      confirmPsdForm: {
        newPsd: '',
        newPsddb: ''
      },
      confirmPsdFormRules: {
        newPsd: [
          { required: true, message: '请输入新密码' },
          {
            // pattern: /(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,12}$)/,
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
            message: '必须包含大小写字母和数字的组合，可以使用特殊字符，长度在 6-12 之间'
          }
        ],
        newPsddb: [
          { required: true, message: '请再次输入新密码' },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
            message: '必须包含大小写字母和数字的组合，可以使用特殊字符，长度在 6-12 之间'
          }
        ]
      },
      // 图形验证码数据
      identifyCodes: '1234567890',
      identifyCode: '',
      countDownTime: 0
    }
  },
  mounted() {
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 5)
  },
  computed: {
    rightPhone() {
      return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(
        this.accountNumForm.phone
      )
    }
  },
  methods: {
    accountNumBtn() {
      this.$refs.accountNumFormRef.validate(async valid => {
        if (!valid) return
        if (this.accountNumForm.identifyCode != this.identifyCode) {
          this.refreshCode()
          return this.$message.error('请输入正确的图形验证码')
        }
        const { data: res } = await this.$http.post('login/edit_password', {
          phoneCode: this.accountNumForm.phoneCode,
          phone: this.accountNumForm.phone,
          type: 3
        })
        if (res.code == 300) {
          this.$router.push('/login')
        }
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }

        this.activeIndex = 1
      })
    },
    // 获取手机验证码
    async getCode() {
      // 如果当前没有计时
      if (!this.countDownTime) {
        // 启动倒计时
        this.countDownTime = 60
        const clock = window.setInterval(() => {
          this.countDownTime--
          if (this.countDownTime <= 0) {
            clearInterval(clock)
          }
          this.content = this.countDownTime + 's后重新发送'
        }, 1000)

        // 发送axios请求
        const { data: res } = await this.$http.post('login/login', {
          type: 'code',
          phone: this.accountNumForm.phone
        })
      }
    },
    confirmPsdBtn() {
      this.$refs.confirmPsdFormRef.validate(async valid => {
        if (!valid) return
        if (this.confirmPsdForm.newPsd != this.confirmPsdForm.newPsddb)
          return this.$message.error('两次输入的密码不一致，请重新输入')
        // 确认密码请求
        const { data: res } = await this.$http.post('login/edit_password', {
          password: this.confirmPsdForm.newPsd,
          phone: this.accountNumForm.phone,
          type: 4
        })
        if (res.code == 300) {
          this.$router.push('/login')
        }
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        // 将登录成功后的token，保存到客户端的localStorage中
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch("EDIT_USERINFO", res.user)
        this.$message.success('密码修改成功')
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000)
      })
    },
    // 图形验证码组件区域S
    // 生成一个随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 5)
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
      }
    }
    // 图形验证码组件区域E
  }
}
</script>

<style lang="less" scoped>
.s-canvas {
  height: 38px;
}

.el-container {
  height: 100%;
}

.el-header {
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;

  .largeImg {
    width: 200px;
    height: auto;
    margin: 10px 0 0 10px;
    cursor: pointer;
  }
}

.psdBox {
  display: flex;
  align-items: center;
  background: url('../../assets/loginBg_3.png') no-repeat;
  background-size: 100%;
  position: relative;
  min-height: 600px;
}

.bgImg {
  width: 100%;
  height: auto;
}

.editPsdBox {
  :deep(.el-card__body) {
    padding: 75px 35px;
  }
}

.el-form {
  width: 400px;
  margin: 50px auto;

  .el-button {
    width: 100%;
  }
}
</style>
