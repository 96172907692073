<template>
  <!-- 个人中心 -->
  <el-row class="personInfo">
    <el-row style="width: 100%;">
      <div class="top-card">
        <img src="@/assets/images/user/user_top_bg_item.png" class="user_top_bg_item" alt="">
        <div class="title">申请媒体账户，开启营销之旅</div>
        <div class="button-list">
          <div class="button" @click="openAccountSelf">
            <div>自助开户</div>
            <img src="@/assets/images/user/arrow_left.png" alt="">
          </div>
          <el-popover placement="right" :width="170" trigger="hover">
            <template #reference>
              <div class="button">
                <div>咨询顾问</div>
                <img src="@/assets/images/user/arrow_right.png" alt="">
              </div>
            </template>
            <template #default>
              <img src="@/assets/images/clientInfo/contact.png" alt="" style="width: 170px; height: 170px;">
              <div>添加官方顾问微信，免费领取YinoCloud操作手册</div>
            </template>
          </el-popover>
        </div>
      </div>
    </el-row>
    <el-row style="width: 100%;">
      <el-col :span="10" style="padding-right: 10px;">
        <el-card class="box-card" style="min-height: 650px">
          <template #header>
            <div class="card-header">
              <span class="SubHead">个人信息</span>
            </div>
          </template>
          <div class="text item">
            <el-form ref="userInfoFormRef" :model="userInfoForm" :rules="userInfoFormRules" label-width="120px"
              label-position="left" class="userInfoForm">
              <el-form-item label="用户名" id="editName">
                <el-row :gutter="10">
                  <el-col :span="20">
                    <el-input v-model="userName" :disabled="editName == 0" clearable size="small"></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text" @click="editNameBtn" v-if="editName == 0">修改</el-button>
                    <el-button type="text" @click="confirmNameBtn" v-else>确定</el-button>
                    <el-button type="text" @click="cancelNameBtn" v-show="editName != 0">取消</el-button>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="">
                <template #label>
                  <span style="margin-right: 10px">手机</span>
                  <el-tag type="success" size="mini">密保手机</el-tag>
                </template>
                <el-row :gutter="10">
                  <el-col :span="20">
                    <el-input v-model="hidePhone" disabled size="small"></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text" @click="editPhoneBtn">修改</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="邮箱">
                <template #label>
                  <span style="margin-right: 10px">邮箱</span>
                  <el-tag type="success" size="mini" v-if="userInfo.is_email == 1">已绑定</el-tag>
                  <el-tag type="warning" size="mini" v-else>绑定后可通过邮件接收信息</el-tag>
                </template>
                <el-row :gutter="10">
                  <el-col :span="20">
                    <el-input v-model="hideEmail" disabled size="small"></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text" v-if="userInfo.is_email == 1" @click="eidtEmail">换绑</el-button>
                    <template v-else-if="userInfo.is_email == 0 && (userInfo.email != '' || userInfo.email != null)">
                      <el-button type="text" @click="verifyEmail">验证</el-button>
                      <el-button type="text" @click="editRawEmail">修改</el-button>
                    </template>
                    <el-button type="text" v-else @click="bindEmail">设置</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="账户密码" id="editPassword">
                <el-row :gutter="10">
                  <el-col :span="20">
                    <el-input v-model="userInfo.password" disabled type="password" size="small"></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text" @click="editPsdBtn">修改</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="微信">
                <template #label>
                  <span style="margin-right: 10px">微信</span>
                  <el-tag type="success" size="mini" v-if="userInfo.w_openid">已绑定</el-tag>
                  <el-tag type="danger" size="mini" v-else>未绑定</el-tag>
                </template>
                <el-row :gutter="10">
                  <el-col :span="20">
                    <el-input v-model="userInfo.w_openid" disabled type="password" size="small"></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text" v-if="userInfo.w_openid && userInfo.w_openid != ''" @click="wxUbind">解绑
                    </el-button>
                    <template v-else>
                      <el-popover placement="top-start" :width="220" trigger="click">
                        <img :src="wxUrl" alt="" :width="200" :height="200" style="display: block; margin: 0 auto" />
                        <p class="HelpText" style="text-align: center; margin: 5px 0">
                          <i class="iconfont icon-z-weixin" style="color: #36c626"></i>
                          扫码关注公众号及时接收信息
                        </p>
                        <template #reference>
                          <el-button type="text" @click="wxImg">绑定</el-button>
                        </template>
                      </el-popover>
                    </template>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
      <el-col :span="14" style="padding-left: 10px;">
        <el-card style="min-height: 650px">
          <el-row class="rightInfo">
            <div class="logoList">
              <img src="@/assets/images/user/meta.png" alt="meta">
              <div class="divider"></div>
              <img src="@/assets/images/user/tiktok.png" alt="tiktok">
              <div class="divider"></div>
              <img src="@/assets/images/user/bing.png" alt="bing">
              <div class="divider"></div>
              <img src="@/assets/images/user/google.png" alt="google">
            </div>

            <el-col :span="24">
              <div class="helpInfo">
                <div class="title">如何使用YinoCloud易诺云开启出海之路</div>
                <div>1、完善个人信息</div>
                <div>2、完成<router-link to="/clientInfo" v-if="userInfo.manage === 1">企业认证</router-link><span
                    v-else>企业认证</span></div>
                <div>3、Facebook、TikTok、Microsoft、Google等主流海外广告账户一键开户，下户后随时随地自助充值开始推广！</div>
              </div>
            </el-col>

            <el-col :span="24">
              <el-alert type="success" show-icon :closable="false" v-if="company_status == 1"
                style="border: 1px solid #67C23A;">
                <template #title>
                  <span class="Text"
                    v-if="$store.getters.role === 0 && $store.getters.userInfo.manage === 2">加入企业成功，请联系企业管理员分配角色</span>
                  <span class="Text" v-else>
                    您已完成企业认证，去<router-link to="/open_account">申请广告账户</router-link>，即刻开启海外营销！
                  </span>
                </template>
              </el-alert>
              <el-alert type="info" show-icon :closable="false" v-else-if="company_status == 0 && !isQbitSource"
                style="border: 1px solid #409EFF;">
                <template #title>
                  <span class="Text">
                    尚未认证企业，点击完成<router-link to="/clientInfo">企业认证</router-link>
                  </span>
                </template>
              </el-alert>
              <el-alert type="error" show-icon :closable="false" v-else-if="company_status == 4"
                style="border: 1px solid #F56C6C;">
                <template #title>
                  <span class="Text">你的认证已被驳回，理由是{{ company.reason }}</span>
                </template>
              </el-alert>
              <el-alert type="warning" show-icon :closable="false" v-else-if="company_status == 2"
                style="border: 1px solid #E6A23C;">
                <template #title>
                  <span class="Text"> 企业认证正在审核中，请联系商务 </span>
                </template>
              </el-alert>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </el-row>

  <!-- 修改手机第一步对话框 -->
  <el-dialog v-model="editPhoneFormDialogVisible" @close="editPhoneFormClose" title="身份验证" width="30%"
    custom-class="myDialog">
    <p>为了你的账户安全，请验证身份。</p>
    <el-form ref="editPhoneFormRef" :rules="editPhoneFormRules" :model="editPhoneForm">
      <el-form-item>
        <el-input size="small" :placeholder="`使用手机${hidePhone}验证 `" disabled></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-input v-model="editPhoneForm.code" placeholder="请输入验证码" size="small" clearable></el-input>
          </el-col>
          <el-col :span="6" style="text-align: right">
            <el-button ref="editPhoneRef" size="small" @click="getCode">
              {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="nextPhoneBtn">下一步</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 修改手机第二步对话框 -->
  <el-dialog v-model="confirmPhoneFormDialogVisible" @close="confirmPhoneFormClose" title="输入新的手机号" width="30%"
    custom-class="myDialog">
    <el-form ref="confirmPhoneFormRef" :rules="confirmPhoneFormRules" :model="confirmPhoneForm">
      <el-form-item prop="phone">
        <el-input v-model="confirmPhoneForm.phone" placeholder="请输入新的手机号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-input v-model="confirmPhoneForm.code" placeholder="请输入验证码" size="small" clearable></el-input>
          </el-col>
          <el-col :span="6" style="text-align: right">
            <el-button ref="editPhoneRef" size="small" :disabled="!rightPhone" @click="getCode">
              {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="prePhoneBtn">上一步</el-button>
        <el-button size="small" type="primary" @click="confirmPhoneBtn">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 设置或者换绑邮箱对话框 -->
  <el-dialog v-model="confirmEmailFormDialogVisible" @close="confirmEmailFormClose" title="身份验证" width="30%"
    custom-class="myDialog">
    <p>为了你的账户安全，请验证身份。</p>
    <el-form ref="confirmEmailFormRef" :rules="confirmEmailFormRules" :model="confirmEmailForm">
      <el-form-item prop="email">
        <el-input v-model="confirmEmailForm.email" size="small" placeholder="请输入邮箱号" clearable></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-input v-model="confirmEmailForm.code" placeholder="请输入邮箱验证码" size="small" clearable></el-input>
          </el-col>
          <el-col :span="6" style="text-align: right">
            <el-button ref="editPhoneRef" size="small" :disabled="!rightEmail" @click="getCode">
              {{ countDownTime > 0 ? `已发送${countDownTime}s` : '发送邮箱验证码' }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="confirmEmailBtn">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 修改不确定邮箱的对话框 -->
  <EditEmailDialog />
</template>

<script>
import $ from 'jquery'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import EditEmailDialog from './EditEmailDialog.vue'
import api from '@/request/api/homepage'
import { getClientInfo } from '@/request/api/client-info'

export default {
  name: 'CompanyInfo',
  components: { EditEmailDialog },
  data() {
    return {
      userInfo: '',
      company_status: '',
      company: '',
      // 微信二维码图片
      wxUrl: '',
      wxKey: '',
      // 微信二维码定时器
      wxClock: undefined,
      userInfoForm: {
        name: '',
        phone: '',
        email: '',
        wechat: '',
      },
      hidePhone: '',
      hideEmail: '',
      userInfoFormRules: {
        name: [{ required: true, message: '请输入正确用户名', trigger: 'blur' }],
      },
      //修改手机对话框
      editPhoneFormDialogVisible: false,
      //   修改手机表单内容
      editPhoneForm: {
        code: '',
      },
      editPhoneFormRules: {
        code: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
      },
      // 第一步修改手机号倒计时默认时间
      countDownTime: 0,
      // 第一步修改手机号定时器
      editPhoneclock: undefined,
      // 换绑确认手机号弹框
      confirmPhoneFormDialogVisible: false,
      confirmPhoneForm: {
        phone: '',
        code: '',
      },
      confirmPhoneFormRules: {
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
      },
      editEmailFormDialogVisible: false,
      editEmailForm: {
        code: '',
      },
      editEmailFormRules: {
        code: [{ required: true, message: '请输入正确的邮箱验证码', trigger: 'blur' }],
      },
      confirmEmailFormDialogVisible: false,
      confirmEmailForm: {
        email: '',
        code: '',
      },
      confirmEmailFormRules: {
        email: [
          { required: true, message: '请输入邮箱号码', trigger: 'blur' },
          {
            pattern: /(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
            message: '请输入正确格式的邮箱号码',
            trigger: 'change',
          },
        ],
        code: [{ required: true, message: '请输入正确的邮箱验证码', trigger: 'blur' }],
      },
      // 绑定邮箱弹框的上一步按钮是否显示
      bindEmailLastStep: true,
      editName: 0,
      userName: '',
      points: 0,
    }
  },
  computed: {
    isQbitSource: (el) => {
      return el.$store.getters.userInfo && el.$store.getters.userInfo.q_type
    },
    rightPhone() {
      return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(
        this.confirmPhoneForm.phone
      )
    },
    rightEmail() {
      return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.confirmEmailForm.email)
    },
  },
  mounted() {
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
    this.phoneNumShow()
    this.emailNumShow()
    this.driver()
    this.$bus.emit('getTask')
    if (this.$store.getters.role === 0 && this.$store.getters.userInfo.manage === 2) {
      this.$message.success('加入企业成功，请联系企业管理员分配角色')
    }
    getClientInfo({ token: this.getUserInfo('token') }).then((res) => {
      if (res.code === 200) {
        this.company_status = res.status
        this.company = res.data
      }
    })
  },
  beforeUnmount() {
    this.$nextTick(() => {
      $('#driver-highlighted-element-stage').hide()
      $('#driver-page-overlay').hide()
      $('#driver-popover-item').hide()
    })
  },
  methods: {
    driver() {
      var taskName = window.localStorage.getItem('taskName')
      var taskPassword = window.localStorage.getItem('taskPassword')

      if (taskName == 1) {
        const driver = new Driver({
          opacity: 0.5,
        })
        driver.highlight({
          element: '#editName',
          popover: {
            title: '设置用户名',
            description: '请修改用户名并点击完成，即可完成新手任务',
            closeBtnText: '确定',
            position: 'right',
          },
        })
        window.localStorage.setItem('taskName', 0)
      }

      if (taskPassword == 1) {
        const driver1 = new Driver({
          opacity: 0.1,
        })
        driver1.highlight({
          element: '#editPassword',
          popover: {
            title: '设置密码',
            description: '请设置密码并点击完成，即可完成新手任务',
            closeBtnText: '确定',
            position: 'right',
          },
        })
        api.finishPasswordTask().then((res) => {
          if (res.code === 200) {
            window.localStorage.setItem('taskPassword', 0)
            window.location.reload()
          }
        })
      }
    },
    //隐藏手机号
    async phoneNumShow() {
      this.userName = this.userInfo.name
      if (!this.userInfo.phone) {
        return
      } else {
        let number = this.userInfo.phone //获取到手机号码字段
        let mphone = number.substring(0, 3) + '****' + number.substring(7)
        this.hidePhone = mphone

        const { data: res } = await this.$http.post('user/is_login')
        this.points = res.integral
        if (res.code == 300) {
          this.$router.push('/login')
        }
      }
    },
    //隐藏邮箱号
    emailNumShow() {
      if (!this.userInfo.email) {
        return
      } else {
        let number = this.userInfo.email
        let memail = number.substring(0, 3) + '***' + number.substring(6)
        this.hideEmail = memail
      }
    },
    // 修改用户名
    editNameBtn() {
      this.editName = 1
    },
    async confirmNameBtn() {
      const { data: res } = await this.$http.post('user/edit_username', {
        name: this.userName,
      })
      if (res.code == 100) return this.$message.error(`${res.msg}`)
      this.editName = 0
      this.$bus.emit('getTask')
      this.$message.success('修改成功')
      window.localStorage.setItem('userInfo', JSON.stringify(res.user))
      this.$store.dispatch('EDIT_USERINFO', res.user)
    },
    cancelNameBtn() {
      this.userName = this.userInfo.name
      this.editName = 0
    },
    // 修改手机号按钮
    editPhoneBtn() {
      this.editPhoneFormDialogVisible = true
    },
    // 验证手机号弹框下一步按钮
    nextPhoneBtn() {
      this.$refs.editPhoneFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('user/edit_phone', {
          phoneCode: this.editPhoneForm.code,
          type: 1,
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }

        this.editPhoneFormDialogVisible = false
        this.confirmPhoneFormDialogVisible = true
      })
    },
    // 换绑对话框上一步
    prePhoneBtn() {
      this.confirmPhoneFormDialogVisible = false
      this.editPhoneFormDialogVisible = true
    },
    // 确定换绑按钮
    confirmPhoneBtn() {
      this.$refs.confirmPhoneFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('user/edit_phone', {
          phone: this.confirmPhoneForm.phone,
          phoneCode: this.confirmPhoneForm.code,
          type: 2,
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.$message.success('修改成功')
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch('EDIT_USERINFO', res.user)
        this.confirmPhoneFormDialogVisible = false
        window.location.reload()
      })
    },
    // 获取修改手机号的验证码
    async getCode() {
      // 如果当前没有计时
      if (!this.countDownTime) {
        // 启动倒计时
        this.countDownTime = 60
        this.editPhoneclock = window.setInterval(() => {
          this.countDownTime--
          if (this.countDownTime <= 0) {
            clearInterval(this.editPhoneclock)
          }
          this.content = this.countDownTime + 's后重新发送'
        }, 1000)

        // 发送axios请求
        if (this.editPhoneFormDialogVisible == true) {
          // 修改手机号第一步弹框
          const { data: res } = await this.$http.post('login/login', {
            phone: this.userInfo.phone,
            type: 'code',
          })
        } else if (this.confirmPhoneFormDialogVisible == true) {
          // 修改手机号第二步弹框
          const { data: res } = await this.$http.post('login/login', {
            phone: this.confirmPhoneForm.phone,
            type: 'code',
          })
        } else if (this.editEmailFormDialogVisible == true) {
          // 修改邮箱第一步弹框
          // const { data: res } = await this.$http.post('', {
          //   email: this.userInfo.email,
          //   type: 'code'
          // })
        } else if (this.confirmEmailFormDialogVisible == true) {
          // 修改邮箱第二步弹框
          const { data: res } = await this.$http.post('user/edit_email', {
            email: this.confirmEmailForm.email,
            type: 1,
          })
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          if (res.code == 200) {
            this.$message.success(`${res.msg}`)
          }
        }
      }
    },
    // 修改手机对话框关闭事件
    editPhoneFormClose() {
      clearInterval(this.editPhoneclock)
      this.countDownTime = 0
    },
    // 修改账号密码按钮
    editPsdBtn() {
      this.$router.push('/reset')
    },
    // 换绑确认手机对话框关闭事件
    confirmPhoneFormClose() {
      clearInterval(this.editPhoneclock)
      this.countDownTime = 0
    },
    // 设置或换绑确认邮箱对话框关闭事件
    confirmEmailFormClose() {
      clearInterval(this.editPhoneclock)
      this.countDownTime = 0
    },
    // 修改能收到验证码的邮箱
    eidtEmail() {
      this.confirmEmailFormDialogVisible = true
    },
    // 修改不确定的邮箱
    editRawEmail() {
      this.$bus.emit('openEditEmail')
    },
    // 无邮箱时候绑定邮箱
    bindEmail() {
      this.confirmEmailFormDialogVisible = true
    },
    // 验证邮箱
    verifyEmail() {
      this.confirmEmailFormDialogVisible = true
      this.confirmEmailForm.email = this.userInfo.email
    },
    // 确认绑定邮箱
    confirmEmailBtn() {
      this.$refs.confirmEmailFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('user/edit_email', {
          email: this.confirmEmailForm.email,
          code: this.confirmEmailForm.code,
          type: 2,
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.$message.success('设置成功')
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch('EDIT_USERINFO', res.user)
        this.confirmEmailFormDialogVisible = false
        window.location.reload()
      })
    },
    async wxUbind() {
      const confirmResult = await this.$confirm('确认要解除微信绑定吗？<br/>即将解绑你的微信账号，确认解除绑定', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).catch((err) => err)

      if (confirmResult !== 'confirm') {
        return
      } else {
        const { data: res } = await this.$http.post('user/edit_whchat', {
          type: 1,
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.$message.success('解绑成功')
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch('EDIT_USERINFO', res.user)
        window.location.reload()
      }
    },
    // 获取微信二维码
    async wxImg() {
      const { data: res } = await this.$http.get('login/wxImg')
      this.wxUrl = res.data.url
      this.wxKey = res.data.key
      this.wxLogin()
    },
    // 微信绑定
    wxLogin() {
      this.wxClock = window.setInterval(async () => {
        const { data: res } = await this.$http.post('user/edit_whchat', {
          titcket: this.wxKey,
          type: 2,
        })
        if (res.code == 100) {
          clearInterval(this.wxClock)
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          clearInterval(this.wxClock)
          this.$router.push('/login')
        }
        if (res.code == 200) {
          clearInterval(this.wxClock)
          this.$message.success('绑定成功')
          window.localStorage.setItem('userInfo', JSON.stringify(res.user))
          this.$store.dispatch('EDIT_USERINFO', res.user)
          window.location.reload()
        }
      }, 1000)
    },
    editTaskName() { },
    editTaskPassword() { },
    openAccountSelf() {
      if (this.userInfo.company_status == 1) {
        this.$router.push('/open_account')
      } else {
        this.$router.push('/clientInfo')
      }
    },
    // 获取用户信息
    getUserInfo(key = '') {
      const info = this.$store.getters.userInfo
      if (key) return info[key] ? info[key] : ''
      return info
    }
  },
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 2000px) {
  .user_top_bg_item {
    right: 10% !important;
  }
}

@media screen and (max-width: 1550px) {
  .title {
    font-size: 24px !important;
    left: 8% !important;
  }

  .button-list {
    left: 8% !important;
  }

  .user_top_bg_item {
    right: 5% !important;
  }
}

.top-card {
  background: url(../../assets/images/user/user_top_bg.png) no-repeat;
  width: 100%;
  max-height: 280px;
  aspect-ratio: 5 / 1;
  background-size: 100% 100%;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;

  .user_top_bg_item {
    width: 473.5px;
    height: 184px;
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translateY(-50%);
  }

  .title {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    position: absolute;
    top: 25%;
    left: 12%;
  }

  .button-list {
    position: absolute;
    top: 60%;
    left: 12%;
    display: flex;
    align-items: center;

    .button {
      width: 120px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:nth-child(1) {
        background: #2B82FF;
      }

      &:nth-child(2) {
        background: #ffffff;
        margin-left: 20px;

        div {
          color: #2B82FF !important;
        }
      }

      div {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;

        &:nth-child(1) {
          color: #ffffff;
        }
      }

      img {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
    }
  }
}

.box-card {
  :deep(.el-button) {
    color: #2b82ff;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}

.userInfoForm {
  .el-form-item {
    display: block;

    .el-input {
      width: 100%;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 1550px) {

    :deep(.el-col-20) {
      max-width: 75%;
      flex: 0 0 75%;
    }

    :deep(.el-col-4) {
      max-width: 25%;
      flex: 0 0 25%;
    }
  }
}

.rightInfo {
  .logoList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 40px;

    .divider {
      width: 1px;
      height: 100%;
      background-color: #F0F0F0;
    }

    img {
      width: 22%;
    }
  }

  .helpInfo {
    width: 450px;

    div {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      text-align: justify;
      margin-bottom: 20px;
    }

    .title {
      font-size: 24px;
      color: #262626;
      margin-bottom: 40px;
    }
  }

  .el-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;

    a {
      text-decoration: none;
      color: #2b82ff;
    }

    .el-alert {
      width: 450px;
    }

    :deep(.el-alert--info.is-light) {
      background-color: #e6f7ff;
      border: 1px solid #91d5ff;
    }

    :deep(.el-icon-info) {
      background-color: #e6f7ff;
      color: #2b82ff;
    }
  }
}

.gradeTag {
  .iconfont {
    font-size: 14px;
    margin-right: 5px;
  }
}

.icon-anchor-full {
  font-size: 14px;
}
</style>
