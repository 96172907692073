<template>
  <el-card class="box-card" style="min-height:100vh;">
    <div style="max-width:1000px;margin: 0 auto;" v-if="status == 0">
      <h1 class="survey-header-title">YinoCloud素材模块用户调研</h1>
      <p class="survey-header-subtitle">
        尊敬的广告主，为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位用户的宝贵意见，期待您的参与！现在我们就马上开始吧!
      </p>
      <el-form ref="surveyFormRef" :model="surveyForm" label-position="top">
        <template v-for="(item, index) in surveyForm" :key="index">
          <el-form-item
            :prop="'[' + index + '].value'"
            :rules="[
              { required: item.required == 1 ? true : false, message: '请选择' + item.title, trigger: 'change' }
            ]"
          >
            <template #label>
              <span class="labelNum">{{ index + 1 + '.' }}</span>
              <span class="labelName">{{ item.title }}</span>
              <div class="question-tags" v-if="item.type == 1">
                <span class="question-tag tag"><span>单选</span></span>
              </div>
              <div class="question-tags" v-if="item.type == 2">
                <span class="question-tag tag"><span>多选</span></span>
              </div>
              <div class="question-tags" v-if="item.type == 3">
                <span class="question-tag tag"><span>单行文本</span></span>
              </div>
              <div class="question-tags" v-if="item.type == 5">
                <span class="question-tag tag"><span>量表</span></span>
              </div>
            </template>
            <div v-if="item.type == 1">
              <el-radio-group v-model="item.value" size="large" @change="radioChange(item.value, index)">
                <template v-for="radio in item.options" :key="radio.id">
                  <el-radio :label="radio.id" v-if="radio.other == 0">{{ radio.options }}</el-radio>
                  <el-radio :label="radio.id" v-if="radio.other == 1">
                    {{ radio.options }}
                    <el-input v-model="radio.value" size="small" clearable />
                  </el-radio>
                </template>
              </el-radio-group>
            </div>
            <div v-if="item.type == 2">
              <el-checkbox-group v-model="item.value" @change="checkChange(item.value, index)">
                <template v-for="check in item.options" :key="check.id">
                  <el-checkbox :label="check.id" v-if="check.other == 0">{{ check.options }}</el-checkbox>
                  <el-checkbox :label="check.id" v-if="check.other == 1">
                    {{ check.options }}
                    <el-input v-model="check.value" size="small" clearable />
                  </el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
            <div v-if="item.type == 3">
              <el-input v-model="item.value" placeholder="请输入" clearable />
            </div>
            <div v-if="item.type == 5">
              <div class="question-tips">
                <span class="tag question-tip"><i>非常不满意</i> </span>
                <span class="tag question-tip"><i>非常满意</i> </span>
              </div>
              <el-radio-group v-model="item.value" size="large" class="rateRadio">
                <el-radio-button label="1" />
                <el-radio-button label="2" />
                <el-radio-button label="3" />
                <el-radio-button label="4" />
                <el-radio-button label="5" />
                <el-radio-button label="6" />
                <el-radio-button label="7" />
                <el-radio-button label="8" />
                <el-radio-button label="9" />
                <el-radio-button label="10" />
              </el-radio-group>
            </div>
          </el-form-item>
        </template>
        <el-form-item style="text-align:center;">
          <el-button type="primary" @click="submitWJ">提交</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="finished" v-else>
      <img src="../../assets/wjFinsih.png" alt="" />
      <p>感谢您的提交<br /><span class="Text">您已获得+100会员积分</span></p>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'questionDialog',
  data() {
    return {
      status: 0,
      surveyForm: []
    }
  },
  mounted() {
    this.getFormDate()
  },
  methods: {
    questionClose() {},
    async getURLParams(url) {
      var cs = url.split('?')[1]
      if (cs) {
        var cc = cs.split('=')[0]
        if (cc == 'source') {
          var cc1 = cs.split('=')[1]
          if (cc1 == 'message' || cc1 == 'Wechat' || cc1 == 'mail') {
            window.localStorage.setItem('source', cc1)
          }
        }
      }
    },
    async getFormDate() {
      const { data: res } = await this.$http.post('user/question_list', {
        type: 1
      })
      this.surveyForm = res.data
      this.status = res.status
      if (res.code == 300) {
        this.$router.push('/login')
      }
    },
    async radioChange(id, index) {
      const { data: res } = await this.$http.post('user/set_question', {
        id: id
      })
      if (res.code == 101) {
        this.surveyForm.splice(6)
        return
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code != 100) {
        res.data.forEach(item => {
          this.surveyForm[index].num = res.data.length
          if (this.surveyForm[index].num > 0) {
            this.surveyForm.splice(index + 1, this.surveyForm[index].num)
          }
          this.surveyForm.splice(index + 1, 0, item)
          index++
        })
      }
      this.$refs.surveyFormRef.clearValidate()
    },
    async checkChange(ids, index) {
      var id = 0
      ids.forEach(item => {
        id = item
      })
      if (id == 0) return
      const { data: res } = await this.$http.post('user/set_question', {
        id: id
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code != 100) {
        this.surveyForm[index].num = res.data.length
        res.data.forEach(item => {
          if (this.surveyForm[index].num > 0) {
            this.surveyForm.splice(index + 1, this.surveyForm[index].num)
          }
          this.surveyForm.splice(index + 1, 0, item)
          index++
        })
      }
      this.$refs.surveyFormRef.clearValidate()
    },
    submitWJ() {
      this.$refs.surveyFormRef.validate(async vaild => {
        if (!vaild) return
        const { data: res } = await this.$http.post('user/send_question', {
          q_id: 2,
          text: this.surveyForm,
          source: window.localStorage.getItem('source')
        })
        if (res.code == 300) {
          this.$router.push('/login')
        }
        if (res.code == 200) {
          this.getFormDate()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.question-tags {
  display: inline-block;
  .question-tag {
    display: inline-block;
    vertical-align: 2px;
    padding: 2px 7px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    font-size: 14px;
    line-height: 1.8;
    span {
      color: #000;
    }
  }
}
.rateRadio {
  flex-direction: row !important;
  :deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
    background-color: #2b82ff;
  }
}
.question-tips {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 495px;
  padding: 12px 0;
  span {
    position: relative;
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 1;
    background-color: rgba(242, 242, 242, 1);
  }
  span:before {
    content: '';
    position: absolute;
    bottom: -5px;
    width: 5px;
    height: 10px;
    background-color: rgba(242, 242, 242, 1);
  }
  span:first-child:before {
    left: 0;
    transform: skewY(-45deg);
    transform-origin: left bottom;
  }
  span:last-child:before {
    right: 0;
    transform: skewY(45deg);
    transform-origin: right bottom;
  }
}
.el-radio-group,
.el-checkbox-group {
  display: flex;
  flex-direction: column;
}
.labelNum {
  font-size: 18px;
  font-weight: bold;
  margin-right: 8px;
}
.labelName {
  font-size: 18px;
}
.survey-header-title {
  text-align: center;
  font-size: 30px;
  color: #000;
  font-weight: normal;
  word-break: break-word;
}
.survey-header-subtitle {
  margin-top: 40px;
  font-size: 16px;
  word-break: break-word;
}
.finished {
  margin: 150px auto;
  text-align: center;
  p {
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
