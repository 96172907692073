<template>
  <!-- 问题反馈 -->
  <el-row>
    <el-col>
      <el-card class="box-card" style="min-height: 800px;">
        <template #header>
          <div class="card-header">
            <span class="SubHead">问题反馈</span>
            <el-link>
              <router-link to="feedbackDetail"><span class="LinkText">反馈详情</span></router-link>
            </el-link>
          </div>
        </template>
        <!-- 选择模块 -->
        <el-row :gutter="20" class="feedSelcectRow">
          <el-col :span="8">
            <div class="feedSelcect" :class="feedSelect == '产品建议' ? 'isChoose' : ''">
              <el-radio v-model="feedSelect" label="产品建议" @change="adFeedChange">
                <i class="iconfont icon-ylchanpinjianyi"></i>
                <span class="title">产品建议</span>
                <p class="HelpText labelText">请输入您对YinoCloud的改进建议或体验类问题</p>
              </el-radio>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="feedSelcect" :class="feedSelect == '功能缺陷' ? 'isChoose' : ''">
              <el-radio v-model="feedSelect" label="功能缺陷" @change="adFeedChange">
                <i class="iconfont icon-ylquexian"></i>
                <span class="title">功能缺陷</span>
                <p class="HelpText labelText">请输入您在使用YinoCloud产品过程中遇到的问题或故障</p>
              </el-radio>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="feedSelcect" :class="feedSelect == '需求工单' ? 'isChoose' : ''">
              <el-radio v-model="feedSelect" label="需求工单" @change="adFeedChange">
                <i class="iconfont icon-ylgongdan"></i>
                <span class="title">需求工单</span>
                <p class="HelpText labelText">请输入您在广告投放中遇到的问题</p>
              </el-radio>
            </div>
          </el-col>
        </el-row>

        <el-form
          ref="feedBackFormRef"
          :model="feedBackForm"
          :rules="feedBackFormRule"
          label-width="120px"
          class="feedForm"
        >
          <span class="HelpText" style="margin-left:44px">提供有效反馈，就可以获得积分+50噢！</span>
          <!-- 选择需求工单时展示 -->
          <!-- <el-form-item label="问题类型" prop="section" v-if="feedSelect == '需求工单'">
            <el-select
              v-model="feedBackForm.problem"
              placeholder="请选择问题类型"
              size="small"
              clearable
              class="feedSelect"
              style="width: 68%"
            >
              <el-option label="Facebook" value="Facebook"></el-option>
              <el-option label="TikTok" value="TikTok"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item :label="feedSelect == '需求工单' ? '问题类型' : '功能板块'" prop="section">
            <el-select
              v-model="feedBackForm.section"
              :placeholder="feedSelect == '需求工单' ? '请选择问题类型' : '请选择功能板块'"
              size="small"
              clearable
              class="feedSelect"
              style="width: 68%"
            >
              <template v-if="feedSelect == '需求工单'">
                <el-option label="Facebook" value="Facebook"></el-option>
                <el-option label="TikTok" value="TikTok"></el-option>
                <el-option label="Google" value="Google"></el-option>
              </template>
              <template v-else>
                <el-option label="注册和登录" value="注册和登录"></el-option>
                <el-option label="账户管理" value="账户管理"></el-option>
                <el-option label="财务管理" value="财务管理"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="内容描述" prop="describe">
            <el-input
              size="small"
              type="textarea"
              placeholder="请输入您的具体内容描述"
              v-model.lazy="feedBackForm.describe"
              style="width: 68%"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传截图">
            <el-upload
              ref="files"
              :action="uploadURL"
              list-type="picture-card"
              :limit="3"
              accept=".jpeg,.png,.jpg"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :headers="headerObj"
            >
              <i class="iconfont icon-ylshangchuantupian"></i>
              <div class="el-upload__text">
                <em class="LinkText">点击上传截图</em><br />
                支持jpeg、png、jpg格式
              </div>
            </el-upload>
            <el-dialog v-model="ImagedialogVisible">
              <img style="width: 100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :disabled="btnFlag" @click="feedBackBtn">提交</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import base from "../../request/base";
export default {
  data() {
    return {
      base: base,
      userInfo: '',
      feedSelect: '产品建议',
      feedBackForm: {
        section: '',
        describe: '',
        path: []
      },
      btnFlag: false,
      // 设置反馈表单验证规则
      feedBackFormRule: {
        section: [{ required: true, message: '选项不能为空', trigger: 'change' }],
        describe: [{ required: true, message: '请输入内容描述', trigger: 'blur' }]
        // file: [{ required: true, message: '请上传截图' }]
      },
      // 图片上传的地址
      uploadURL: `${base.admin_url}/cloud/file/uploadfile_feedback`,
      ImagedialogVisible: false,
      dialogImageUrl: '',
      // 图片上传时的headers请求头
      headerObj: {
        Authorization: this.$store.getters.userInfo.token
      }
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.isLogin()
  },
  methods: {
    async isLogin() {
      const { data: res } = await this.$http.post('user/is_login', {
        token: this.userInfo.token
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
    },
    adFeedChange() {
      this.$refs.feedBackFormRef.resetFields()
      this.$refs.feedBackFormRef.clearValidate()
    },
    // 移除图片的按钮
    handleRemove(file) {
      // console.log(file.response.path);
      let index = this.feedBackForm.path.findIndex(item => item == file.response.path)
      this.feedBackForm.path.splice(index, 1)
    },
    // 处理图片预览功能
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      this.dialogImageUrl =  file.response.path1
      this.ImagedialogVisible = true
    },
    // 图片上传成功的回调
    handleSuccess(response) {
      this.feedBackForm.path.push(response.path)
      console.log(this.feedBackForm.path)
    },
    feedBackBtn() {
      this.btnFlag = true
      setTimeout(() => {
        this.btnFlag = false
      }, 60000)

      // this.$message({
      //   message: '点击提交后请60s后再试',
      //   type: 'warning'
      // })

      this.$refs.feedBackFormRef.validate(async vaild => {
        if (!vaild) return
        const { data: res } = await this.$http.post('user/feedback', {
          type: this.feedSelect,
          function: this.feedBackForm.section,
          content: this.feedBackForm.describe,
          path: this.feedBackForm.path
        })

        if (res.code == 100) this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
        if (res.code == 200) {
          this.$refs.feedBackFormRef.resetFields()
          this.$message.success(`${res.msg}`)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.feedSelcectRow {
  padding: 0 45px;
  margin-bottom: 20px;
}
.feedSelcect {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  input[type='radio'] {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  i {
    font-size: 50px;
    margin-bottom: 15px;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
  }
}
:deep(.el-radio__label) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feedSelect {
  width: 100%;
}
.feedForm {
  margin-top: 60px;
  padding-right: 40%;
}
.feedSelcect.isChoose {
  border: 1px solid #2b82ff !important;
}
:deep(.el-upload),
:deep(.el-upload-dragger) {
  width: 68% !important;
  height: 100% !important;
  .el-upload__text {
    position: relative;
    bottom: 33px;
    line-height: 1.5;
  }
  em {
    font-style: normal;
  }
}
.el-upload--picture-card i {
  font-size: 60px;
}
@media screen and (max-width: 1660px) {
  .labelText {
    width: 250px;
    white-space: normal;
  }
}
</style>
